<template>
	<Dialog :dialog="checkoutDialog" :dialogWidth="dialogWidth" dense>
		<template v-slot:title> Select {{ fileTab }} </template>
		<template v-slot:body>
			<v-container fluid class="px-4">
				<template>
					<v-row>
						<v-col md="12">
							<v-layout>
								<v-flex md11>
									<TextInput v-model="search" hide-details placeholder="Search..."></TextInput>
								</v-flex>
								<v-flex v-if="search">
									<v-btn
										v-on:click="
											search = null;
											getAssets();
										"
										tile
										depressed
										class="mt-3"
										color="red lighten-1 white--text"
									>
										<v-icon>mdi-close</v-icon> Cancel</v-btn
									>
								</v-flex>
								<v-flex>
									<v-btn
										v-on:click="getAssets()"
										tile
										depressed
										class="mt-3"
										color="blue darken-4 white--text"
									>
										<v-icon>mdi-magnify</v-icon> Search</v-btn
									>
								</v-flex>
							</v-layout>
						</v-col>
						<v-col md="12">
							<div>
								<table width="100%">
									<template v-if="assetList.length">
										<tr
											v-on:click="selectAsset(data)"
											v-for="(data, index) in assetList"
											:key="index"
											class="cursor-pointer border-light-grey"
										>
											<td width="20%" align="center">
												<ImageTemplate width="80" circle :src="data.image"></ImageTemplate>
											</td>
											<td width="80%">
												<div class="py-2">
													<Chip color="blue darken-4" :text="data.barcode"></Chip>
													<p class="mt-2 mb-1 font-level-3" v-if="data.id_number">
														Asset ID <span class="bold-500">{{ data.id_number }}</span>
													</p>
													<p class="mb-1 font-level-3" v-if="data.name">
														Asset Name <span class="bold-500">{{ data.name }}</span>
													</p>
												</div>
											</td>
										</tr>
									</template>
									<template v-else>
										<tr>
											<td colspan="2" align="center" height="93.75px" class="border-light-grey">
												<div class="py-2">
													<p class="mb-0 font-level-3 bold-600">Oops... Nothing Found</p>
												</div>
											</td>
										</tr>
									</template>
								</table>
							</div>
							<br />
							<perfect-scrollbar
								v-if="false"
								:options="{ suppressScrollX: true }"
								class="scroll"
								style="max-height: 60vh; position: static"
							>
								<v-list subheader three-line>
									<v-skeleton-loader
										v-if="dataLoading"
										class="custom-skeleton"
										type="list-item-avatar-two-line"
									>
									</v-skeleton-loader>
									<template v-else>
										<template v-if="assetList.length > 0">
											<v-list-item
												class="border border-secondary mb-3"
												v-on:click="selectAsset(data)"
												v-for="(data, index) in assetList"
												:key="index"
												link
											>
												<v-list-item-avatar>
													<ImageTemplate circle :src="data.image"></ImageTemplate>
												</v-list-item-avatar>
												<v-list-item-content>
													<v-list-item-title class="font-size-16">
														<Chip color="blue darken-4" :text="data.barcode"></Chip>
													</v-list-item-title>
													<v-list-item-subtitle class="font-size-14">
														<b>{{ data.name }}</b>
													</v-list-item-subtitle>
												</v-list-item-content>
												<v-list-item-action class="margin-auto">
													<v-list-item-action-text class="font-size-16"
														><b>Cost</b> {{ data.cost_price_formatted }}
													</v-list-item-action-text>
												</v-list-item-action>
											</v-list-item>
										</template>
										<template v-else>
											<v-list-item>
												<v-list-item-content>
													<v-list-item-title class="font-size-16">No Asset(s) Found </v-list-item-title>
												</v-list-item-content>
											</v-list-item>
										</template>
									</template>
								</v-list>
							</perfect-scrollbar>
						</v-col>
					</v-row>
				</template>
			</v-container>
		</template>
		<template v-slot:action>
			<v-btn
				class="mx-2 custom-grey-border custom-bold-button"
				color="blue darken-4 white--text"
				tile
				depressed
				v-on:click="$emit('close', true)"
			>
				Close
			</v-btn>
		</template>
	</Dialog>
</template>

<script>
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import { GetAssetList, GetAssetStocksList } from "@/core/lib/checked-out.lib";
import ImageTemplate from "@/view/components/Image";
import Chip from "@/view/components/Chip";

export default {
	data() {
		return {
			dataLoading: false,
			fileTab: "assets",
			searchLoader: true,
			assetList: [],
			assetStockList: [],
			expenseDialog: false,
			search: null,
			contractor: null,
			timeoutLimit: 500,
			timeout: null,
			contractorArr: new Object(),
			createContractorDialog: false,
			contractorList: new Array(),
			dialog: false,
		};
	},
	props: {
		checkoutDialog: {
			type: Boolean,
			default: false,
		},
		dialogWidth: {
			type: Number,
			default: 768,
		},
		assetOnly: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			default: null,
		},
	},
	components: {
		Dialog,
		Chip,
		TextInput,
		ImageTemplate,
	},
	watch: {
		checkoutDialog: {
			deep: true,
			immediate: true,
			handler(param) {
				if (param) {
					this.getAssets();
					// this.getAssetStocks();
				}
			},
		},
	},
	methods: {
		getAssets() {
			const _this = this;
			GetAssetList(this.type, this.search)
				.then((data) => {
					_this.assetList = data;
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.dataLoading = false;
				});
		},
		getAssetStocks() {
			const _this = this;
			GetAssetStocksList(this.type)
				.then((data) => {
					_this.assetStockList = data;
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.dataLoading = false;
				});
		},
		selectAsset(data) {
			this.$emit("selectRelatedTo", {
				id: data.uuid,
				type: "Asset",
				endpoint: "asset",
				checkout_uuid: data.checkout_uuid,
			});
		},
		selectAssetStock(data) {
			this.$emit("selectRelatedTo", { id: data.uuid, type: "Asset Stock", endpoint: "asset-stock" });
		},
	},
	/*  mounted(){
      this.getAssets();
			this.getAssetStocks();
  } */
};
</script>
